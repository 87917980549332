<template>
    <div id="start">

        <!-- Bottom Navigation -->
        <nav class="navbar navbar-dark bg-danger navbar-expand d-xl-none fixed-bottom" style="max-height:50px; font-size: 22px;" id="navbar-wd">
            <ul class="navbar-nav nav-justified w-100">
            <li class="nav-item">
            <a href="#intro" class="nav-link">
            <i class="fa fa-home"></i>
            </a>
            </li>
            <li class="nav-item">
            <a href="#event" class="nav-link">
            <i class="fa fa-calendar"></i>
            </a>
            </li>
            <li class="nav-item">
            <a href="#story" class="nav-link">
            <i class="fa fa-heart"></i>
            </a>
            </li>
            <li class="nav-item">
            <a href="#gallery" class="nav-link">
            <i class="fa fa-photo"></i>
            </a>
            </li>
            <li class="nav-item">
            <a href="#contact" class="nav-link">
            <i class="fa fa-comments"></i>
            </a>
            </li>
            <li class="nav-item">
            <a href="javascript:void(0);" onclick="PlaySound()" value="0" class="nav-link">
            <i class="icon-sound fa fa-pause"></i>
            </a>
            </li>
            </ul>
        </nav>
        <!-- End Bottom Navigation -->

        <!-- LOADER -->
        <div id="preloader">
            <div class="preloader pulse">
            <i class="fa fa fa-heart" aria-hidden="true"></i>
            </div>
        </div>
        <!-- END LOADER -->

        <!-- Modal -->
        <div class="modal fade" id="modalOpening" tabindex="-1" role="dialog" aria-labelledby="modalOpeningTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered wow bounceIn" data-wow-delay="1s" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <center>
                            <h2 class="pt-4">{{ urlParam ? urlParam : 'Hi,' }}</h2>
                            <p>
                                You're invited <br/>
                                The Wedding Celebration of 
                                <span>
                                    <p>
                                        <strong style="color:#dd666c; font-family: 'Marck Script', cursive; font-size:40px;">{{ this.coupleName }}</strong>
                                    </p>
                                </span>
                            </p>
                            <div class="pt-2 pb-4">
                                <button type="button" onclick="PlaySound()" value="1" class="btn btn-danger" data-dismiss="modal">
                                    Open Invitation
                                </button>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </div>

        <Banner/>

        <div id="start-bg">
            <Couple/>
            <Event/>
            <Story/>
            <Gallery/>
            <Gift/>
            <Contact :urlParam="this.urlParam" />
        </div>

        <!-- Start Footer -->
        <footer class="footer-box d-none d-xl-block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="footer-company-name">
                            &copy; 2020 by <b onClick="window.open('https://ondangan.website', '_blank', 'noopener')">ondangan.website</b>
                            <!-- | Template by <a target="_blank" href="https://themewagon.com/">ThemeWagon</a> -->
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        <!-- End Footer -->

        <!-- Audio -->
        <audio id="myAudio">
            <source src="/sounds/bensound-acousticbreeze.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>

        <button type="button" onclick="PlaySound()" value="1" class="d-none d-lg-block btn btn-circle btn-sound" title="Play Audio" style="background:#63c7bd;">
            <i class="icon-sound fa fa-pause"></i>
        </button>
        <!-- End Audio -->

        <!-- Back to Top -->
        <button type="button" id="back-to-top" class="d-none d-lg-block btn btn-danger btn-circle back-to-top" title="Back to Top">
            <i class="fa fa-arrow-up"></i>
        </button>
        <!-- Back to Top -->

    </div>
</template>

<script>
    import Banner from '@/components/Banner.vue'
    import Couple from '@/components/Couple.vue'
    import Event from '@/components/Event.vue'
    import Story from '@/components/Story.vue'
    import Gallery from '@/components/Gallery.vue'
    import Gift from '@/components/Gift.vue'
    import Contact from '@/components/Contact.vue'

    export default {
        name: 'Index',
        data() {
            return {
                urlParam: ''
            }
        },
        components: {
            Banner,
            Couple,
            Event,
            Story,
            Gallery,
            Gift,
            Contact,
        },
        created() {
            let url = new URL(location.href).searchParams.get('to')
            this.urlParam = url
        },
    }
</script>

<style scoped>
	#start-bg {
		background: linear-gradient(to right top, #ffd4d4cc, rgba(255, 255, 255, 0.5)), url("/photos/event/background.jpg") no-repeat fixed;
		background-size: cover;
	}
	.modal-body {
		background: linear-gradient(to right top, #ffd4d4cc, rgba(255, 255, 255, 0.5)), url("/photos/event/red.jpg");
		background-size: cover;
	}
</style>