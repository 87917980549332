<template>
	<div>
        <div class="container" style="padding-top: 50px; padding-bottom: 20px;">
			<div class="row">
				<div class="col-lg-12">
					<div class="title-box wow zoomIn" id="event">
						<hr>
						<span class="mbr-fonts-style display-font" style="font-size: 18px;">
							“Dan diantara tanda-tanda kekuasaan-Nya ialah diciptakan-nya pasangan hidup dari jenismu sendiri
							supaya kamu mendapatkan ketenangan hati, dan dijadikan-Nya Kasih Sayang diantara kamu,
							Sesungguhnya yang demikian itu menjadi tanda-tanda kebesaran-Nya bagi orang-orang yang berfikir.”
						</span>
						<br><hr>
						<i><b>QS. Ar-Rum : 21</b></i>
					</div>
				</div>
			</div>
		</div>
        <div class="contact-box" style="padding-bottom: 20px;">
            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-lg-12">
                        <div class="title-box wow zoomInDown" id="couple">
                        <img src="/photos/couple/flower-top.png" alt="" width="300px"><br><br>
                            <h3>
                                <i><b>Assalamu'alaikum Warahmatullaahi Wabarakaatuh </b></i>
                            </h3>
                            <p>Maha Suci Allah yang telah menciptakan makhluk-Nya berpasang-pasangan. Ya Allah semoga ridho-Mu tercurah mengiringi pernikahan kami.</p>
                        </div>
                    </div>
                </div>
                <div class="row text-center justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="event-inner wow fadeIn">
                            <div class="event-img">
                                <img class="img-fluid" src="/photos/couple/2.jpg" alt="">
                            </div>
                            <h2>Romeo</h2>
                            <p>Anak ke-2 dari Bapak Fulan dan Ibu Fulanah</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="event-inner wow fadeIn">
                            <div class="event-img">
                                <img class="img-fluid" src="/photos/couple/1.jpg" alt="">
                            </div>
                            <h2>Juliet</h2>
                            <p>Anak ke-3 dari Bapak Fulan dan Ibu Fulanah</p>
                        </div>
                    </div>
                </div>
                <!-- <center><img src="/photos/couple/flower-bottom.png" alt="" width="300px" ></center><br><br> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Couple',
    data() {
		return {
            resultName: ''
        }
    },
    methods: {
        copyText(id) {
            var copyText = document.getElementById(id);  
            var input = document.createElement("textarea");
            input.value = copyText.textContent;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            input.remove();
            this.$toast.default('Copied', {
				position: "bottom",
				dismissible: true
			})
        }
    },
    created() {
		this.resultName = this.coupleName.split(" ");
	},
}
</script>