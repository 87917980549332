import moment from 'moment'
import "moment/locale/id";

const MyPlugin = {
    install(Vue, options) {
        Vue.prototype.coupleName = 'Romeo & Juliet'
        Vue.prototype.textEvent = 'Wedding of Romeo %26 Juliet'
        Vue.prototype.textTime = 'Pukul 11.00 - 13.00'
        Vue.prototype.dateEvent = '20201206T110000Z+07:00/20201206T130000Z+07:00'
        Vue.prototype.locationEvent = 'Ascott%20Sudirman%20Jakarta'
        Vue.prototype.urlEvent = 'https://ondangan.website'
        Vue.prototype.maps = 'https://goo.gl/maps/dxNH5cJHdUmn2sGP7'
        Vue.prototype.youtube = 'https://www.youtube.com/embed/LShjbZQtYvM?controls=0'
        
        Vue.mixin({
            data: function () {
                return {
                    textDate: '',
                    eventDate: ''
                }
            },
            created: function () {
                this.textDate = this.format_date('dddd, LL');
                this.eventDate = this.format_date('YYYY/MM/DD hh:mm:ss');
            },
            methods: {
                format_date(param){
                    let date = new Date();
                    date.setDate(date.getDate()+7)
                    return moment.unix(date/1000).format(param);
                },
            }
        });
    }
}

export default MyPlugin