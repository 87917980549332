import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import Global from './plugins/global'

import VueChatScroll from 'vue-chat-scroll'
import VueKeepScrollPosition from 'vue-keep-scroll-position'
import VueToast from 'vue-toast-notification';
import VueLetterAvatar from 'vue-letter-avatar';
import 'vue-toast-notification/dist/theme-default.css';
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(Global);
Vue.use(VueChatScroll);
Vue.use(VueKeepScrollPosition);
Vue.use(VueToast);
Vue.use(VueLetterAvatar);

new Vue({
  'router': Router, 
  render: h => h(App),
}).$mount('#app')