<template>
	<div class="contact-box" style="padding-bottom: 20px;">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="title-box wow wow-not-repeat zoomInDown" id="gift">
						<h2>Wedding <span>gift</span></h2>
                        <p>Doa restu Anda merupakan karunia yang sangat berarti bagi kami. Dan jika memberi adalah ungkapan tanda kasih Anda, Anda dapat memberi kado secara cashless.</p>
					</div>
				</div>
			</div>
			<div class="row text-center justify-content-center">
				<div class="col-lg-4 col-md-6 col-sm-12">
					<div class="event-inner wow bounceInLeft">
						<img src="/images/logo-bni.png" alt="logo-bni" width="120px;">
                        <p class="pt-2">
                            <span id="copy1">9927262652</span>
                            <br>a.n Romeo
                        </p>
                        <button type="button" class="btn btn-danger" @click="copyText('copy1')">
                            Copy Number
                        </button>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-12">
					<div class="event-inner wow bounceInRight">
						<img src="/images/logo-bca.png" alt="logo-bca" width="120px;">
                        <p class="pt-2">
                            <span id="copy2">68543332</span>
                            <br>a.n Juliet
                        </p>
						<button type="button" class="btn btn-danger" @click="copyText('copy2')">
                            Copy Number
                        </button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Gift',
    methods: {
        copyText(id) {
            var copyText = document.getElementById(id);  
            var input = document.createElement("textarea");
            input.value = copyText.textContent;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            input.remove();
            this.$toast.default('Copied', {
				position: "bottom",
				dismissible: true
			})
        }
    }
}
</script>