<template>
	<div class="gallery-box">
		<div class="container-fluid">
			<div class="title-box wow bounceIn" id="gallery">
				<h2>Gallery</h2>
			</div>
			<div class="row">
				<div class="col-12">
					<ul class="nav nav-tabs small justify-content-center text-center" role="tablist">
						<li class="nav-item"><span style="background:none; cursor:pointer;border:none;" class="nav-link active" data-toggle="tab" href="#tab1" role="tab">Photos</span></li>
						<li class="nav-item"><span style="background:none; cursor:pointer;border:none;" class="nav-link" data-toggle="tab" href="#tab2" role="tab">Video</span></li>
					</ul>
				</div>
				<div class="col-12">
					<div class="tab-content py-4">
						<div class="tab-pane active" id="tab1" style="margin-left:-15px;margin-right:-15px">
							<ul class="popup-gallery clearfix">
								<li v-for="(image, key) in images" :key="key" class="wow zoomInDown">
									<a :href="images[key]">
										<img class="img-fluid" :src="thumbs[key]" alt="single image">
										<span class="overlay"><i class="fa fa-heart-o" aria-hidden="true"></i></span>
									</a>
								</li>
							</ul>
						</div>
						<div class="tab-pane" id="tab2">
						<center>
							<iframe width="100%" height="400" :src="this.youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</center>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Gallery',
	data() {
		return {
			thumbs: [
				'photos/gallery/thumb (1).jpg',
				'photos/gallery/thumb (2).jpg',
				'photos/gallery/thumb (3).jpg',
				'photos/gallery/thumb (4).jpg',
				'photos/gallery/thumb (5).jpg',
				'photos/gallery/thumb (6).jpg',
				'photos/gallery/thumb (7).jpg',
				'photos/gallery/thumb (8).jpg',
			],
			images: [
				'photos/gallery/thumb (1).jpg',
				'photos/gallery/thumb (2).jpg',
				'photos/gallery/thumb (3).jpg',
				'photos/gallery/thumb (4).jpg',
				'photos/gallery/thumb (5).jpg',
				'photos/gallery/thumb (6).jpg',
				'photos/gallery/thumb (7).jpg',
				'photos/gallery/thumb (8).jpg',
			],
		}
	}
}
</script>

<style scoped>
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	font-weight: bold;
}
</style>