<template>
	<div>
		<div style="" class="tm-parallax">
			<div class="container-fluid tm-parallax-header">
				<center style="padding-top: 70px;">
					
					<span class="before">
						<span class="add-to-calendar" v-on:click="addCalendar" data-toggle="tooltip" data-placement="right" title="Add to Calendar">
							<span class="day wow bounceInUp">{{ this.textDate }}</span><br>
							<span class="time wow bounceInUp">{{ this.textTime }}</span><br><br>
						</span>
						<div class="submit-button text-center wow fadeIn" data-wow-delay="1s">
							<a target="_blank" :href="this.maps" rel="noopener" class="btn btn-danger" style="pointer-events: all; cursor: pointer;">
								Open Maps
							</a>
						</div>
					</span>
					<span class="after" style="display:none;">
						<span class="day wow bounceInUp">Akad Nikah</span><br>
						<span class="time wow bounceInUp">{{ this.textDate }}</span><br><br><br>
						<div class="submit-button text-center wow bounceIn" data-wow-iteration="10" data-wow-delay="1s">
							<i class="fa fa-heart fa-2x"></i>
						</div>
						<div class="wow bounceIn text-after" style="padding-top: 50px; padding-bottom: 40px;">
							<span>Alhamdulillah SAH</span>
						</div>
					</span>

					<ul class="countdown wow zoomIn">
					    <p class="footer">ready for the remaining time</p>
					    <li>
					        <span class="days">00</span>
					        <p class="days_ref">days</p>
					    </li>
					    <li class="seperator">.</li>
					    <li>
					        <span class="hours">00</span>
					        <p class="hours_ref">hours</p>
					    </li>
					    <li class="seperator">:</li>
					    <li>
					        <span class="minutes">00</span>
					        <p class="minutes_ref">minutes</p>
					    </li>
					    <li class="seperator">:</li>
					    <li>
					        <span class="seconds">00</span>
					        <p class="seconds_ref">seconds</p>
					    </li>
					</ul>

					<p class="closing-event display-font wow fadeInUp" data-wow-delay="1s" style="font-size: 18px; color:white; padding-top:-300px;">
						Ungkapan terima kasih yang tulus dari kami atas kehadiran dan do’a restu Bapak/Ibu/Saudara/i
					</p>

					<!-- <div class="d-none d-lg-block" style="font-size: 30px; margin-top: -60px; ">
						<a href="#story"><i class="fa fa-angle-double-down" style="color: #fff;"></i></a>
					</div> -->
				</center>
			</div>
		</div>

		<div class="container pt-5 pb-4">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12">
					<div class="row align-items-center about-main-info">
						<div class="col-lg-4 col-md-6 col-sm-12">
							<div class="about-img wow wow-not-repeat swing">
								<img class="img-fluid rounded d-lg-none d-md-none d-sm-block" src="images/info-1.png"/>
								<img class="img-fluid rounded d-none d-md-block" src="images/info-2.png"/>
							</div>
						</div>
						<div class="col-lg-8 col-md-6 col-sm-12 wow flash">
							<center>
								<h2 class="d-none d-lg-block">Protokol Pencegahan <span>Covid-19</span></h2>
								<p>Acara ini menerapkan protokol kesehatan pencegahan Covid-19.<br>
								Di mohon untuk mematuhi protokol kesehatan selama berada di area acara pernikahan</p>
							</center>
						</div>
					</div>
					<span class="d-lg-none d-md-none d-sm-block">
						<br><hr>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Event',
	methods: {
		addCalendar: function() {
			let url = "https://calendar.google.com/calendar/render?"+
						"action=TEMPLATE"+
						"&text="+this.textEvent+
						"&dates="+this.dateEvent+
						"&details="+this.urlEvent+
						"&location="+this.locationEvent+
						"&sf=true&output=xml";
			window.open(url, '_blank', 'noopener')
		}
	},
	mounted() {
		$('.countdown').downCount({
			date: this.eventDate,
			offset: +10
		}, function () {
			$('.countdown').hide();
			$('.before').hide();
			$('.after').show();
			$('.closing-event').text('“Baarakallahu laka wa baarakaa alaika wa jamaa bainakumaa fii khoir”');
		});
	},
}
</script>

<style scoped>
	/* image-paralax */
    .tm-parallax {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        height: 600px;
        background-image: url('/photos/event/4.jpg');
        position: relative;
    }

    .tm-parallax-header {
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        color: white;
    }

    /* countdown */
    ul.countdown {
        list-style: none;
        margin: 60px 0px 20px 0px;
        padding: 0;
        display: block;
        text-align: center;
    }

    ul.countdown li {
        display: inline-block;
    }

	/* Desktop */
	@media (min-width: 460px) {
		ul.countdown li span {
			font-size: 80px;
		}
		ul.countdown li.seperator {
			font-size: 80px;
		}
		.day {
			font-size: 35px;
		}
		.time {
			font-size: 26px;
		}
		.text-after span {
			font-size: 60px;
		}
	}

	/* Mobile */
	@media (max-width: 460px) {
		ul.countdown li span {
			font-size: 50px;
		}
		ul.countdown li.seperator {
			font-size: 40px;
		}
		.day {
			font-size: 22px;
		}
		.time {
			font-size: 20px;
		}
		.text-after span {
			font-size: 30px;
		}
	}

    ul.countdown li span {
        font-weight: 300;
        line-height: 80px;
    }

    ul.countdown li.seperator {
        line-height: 70px;
        vertical-align: top;
    }

    ul.countdown li p {
        color: #a7abb1;
        font-size: 14px;
    }

	.add-to-calendar {
		cursor: pointer;
	}
</style>
